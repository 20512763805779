import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { Field } from 'react-final-form';
import { cityQuery } from '../../../queries';
import SearchPopup from '../SearchPopup';
import SearchCity from '../SearchCity';
import Loader from '../../Loader';

const CitiesGroup = ({
  values,
  toggleHandlerCities,
  citiesPopupIsOpen,
  setCitiesLoading,
  setErrorUI
}) => {
  const { t } = useTranslation();
  const cityId = {
    id: values.city_id
  };
  const { data, loading, error } = useQuery(cityQuery(cityId), {
    variables: cityId
  });

  useEffect(() => {
    if (error) setErrorUI(true);
  }, [error]);

  const cityName =
    data && data.city !== null ? data.city.name : t('search.city_search.any');

  useEffect(() => {
    setCitiesLoading(loading);
  }, [loading]);

  return (
    <>
      <Field name="city_id" component="input" type="hidden" />
      <div className="search-form__city">
        <div className="search-form__city_label" data-cy="city-label">
          {t('search.city_search.label')}
        </div>
        <Loader loading={loading} optionalClass="search-form__city_loader">
          <div
            className="search-form__city_name"
            id="search-form-city-name"
            onClick={toggleHandlerCities}
            onKeyPress={toggleHandlerCities}
            role="button"
            tabIndex="0"
            data-cy="chosen-city"
          >
            {cityName}
          </div>
        </Loader>
        <SearchPopup
          modalId="select-city"
          closeHandler={toggleHandlerCities}
          isOpen={citiesPopupIsOpen}
          popupTitle={t('search.city_search.title')}
          inputPlaceholder={t('search.city_search.placeholder')}
          additionalClass="search-popup--cities"
        >
          {(searchString, isFocused, cancelHandler) => (
            <SearchCity
              closePopupHandler={toggleHandlerCities}
              {...{ isFocused, searchString, cancelHandler }}
            />
          )}
        </SearchPopup>
      </div>
    </>
  );
};

CitiesGroup.propTypes = {
  values: PropTypes.object,
  citiesPopupIsOpen: PropTypes.bool,
  toggleHandlerCities: PropTypes.func,
  setCitiesLoading: PropTypes.func,
  setErrorUI: PropTypes.func
};

export default CitiesGroup;
