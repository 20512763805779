import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { Field } from 'react-final-form';
import { cityQuery } from '../../../queries';
import SearchPopupLocations from '../SearchPopupLocations';
import SearchLocations from '../SearchLocations';
import useLocationsPlaceholder from '../../../hooks/useLocationsPlaceholder';
import Loader from '../../Loader';

const LocationGroup = ({
  formValues,
  currentCityIsDeferredHandler,
  locationsPopupIsOpen,
  locationsPopupWillOpen,
  currentCity,
  toggleHandlerLocations,
  chosenLocationNames,
  toggleHandlerCities,
  setErrorUI
}) => {
  const { t } = useTranslation();
  const cityId = currentCity || formValues.city_id;
  const locationsPlaceholder = useLocationsPlaceholder(cityId);

  const { data, loading, error } = useQuery(
    cityQuery({
      id: cityId
    }),
    {
      variables: {
        id: cityId
      }
    }
  );

  useEffect(() => {
    if (error) setErrorUI(true);
  }, [error]);

  const cityName =
    data && data.city !== null ? data.city.name : t('search.city_search.any');

  const popupTopTools = () => {
    return (
      <div className="search-popup__top-tools-container">
        <div className="search-popup__top-tools">
          <span className="search-form__city" data-cy="city-container">
            <div className="search-form__city_label" data-cy="city-label">
              {t('search.city_search.label')}
            </div>
            <Loader
              loading={loading}
              optionalClass="search-popup__top-tools_loader"
            >
              <div
                className="search-form__city_name"
                onClick={toggleHandlerCities}
                onKeyPress={toggleHandlerCities}
                role="button"
                tabIndex="0"
              >
                {cityName}
              </div>
            </Loader>
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    currentCityIsDeferredHandler(locationsPopupWillOpen);
  }, [locationsPopupWillOpen]);

  return (
    <div className="search-form__group">
      <Field name="location_ids" component="input" type="hidden" />
      {locationsPlaceholder && (
        <div className="search-form__locations">
          <div
            className="search-form__locations_row"
            id="search-form-locations-row"
            onClick={toggleHandlerLocations}
            onKeyPress={toggleHandlerLocations}
            role="button"
            tabIndex="0"
            data-cy="chosen-locations"
          >
            {chosenLocationNames?.length ? (
              <div
                className="search-form__locations_names"
                data-cy="chosen-locations-names"
              >
                {chosenLocationNames.join(', ')}
              </div>
            ) : (
              <div
                className="search-form__locations_placeholder"
                data-cy="chosen-locations-placeholder"
              >
                {locationsPlaceholder}
              </div>
            )}
          </div>
        </div>
      )}
      <SearchPopupLocations
        modalId="locations"
        closeHandler={toggleHandlerLocations}
        isOpen={locationsPopupIsOpen}
        popupTitle={t('search.locations_search.title')}
        inputPlaceholder={locationsPlaceholder}
        additionalClass="search-popup--locations"
        topTools={popupTopTools(formValues)}
        reset={true}
      >
        {(
          searchString,
          isFocused,
          cancelHandler,
          isSubmittedForm,
          setRef,
          isInputOnFocus
        ) => {
          return (
            <SearchLocations
              closePopupHandler={toggleHandlerLocations}
              {...{
                isFocused,
                searchString,
                cancelHandler,
                isInputOnFocus
              }}
            />
          );
        }}
      </SearchPopupLocations>
    </div>
  );
};

LocationGroup.propTypes = {
  formValues: PropTypes.object,
  locationsPopupIsOpen: PropTypes.bool,
  locationsPopupWillOpen: PropTypes.bool,
  chosenLocationNames: PropTypes.array,
  currentCity: PropTypes.string,
  currentCityIsDeferredHandler: PropTypes.func,
  toggleHandlerLocations: PropTypes.func,
  toggleHandlerCities: PropTypes.func,
  setErrorUI: PropTypes.func
};

export default memo(LocationGroup);
