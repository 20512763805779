import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { citiesPopularListQuery } from '../../../queries';
import Loader from '../../Loader';
import RadioButton from '../../RadioButton';

import './styles.scss';

const PopularCities = ({ onChangeHandler, currentCity, setErrorUI }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(citiesPopularListQuery);

  const items = (data && data.cities) || [];
  const citiesList = items.map(city => (
    <RadioButton
      key={city.id}
      data={city}
      onChangeHandler={onChangeHandler}
      currentValue={currentCity}
    />
  ));

  useEffect(() => {
    if (error) setErrorUI(true);
  }, [error]);

  return (
    <div className="popular-cities" data-cy="popular-cities-container">
      <div className="popular-cities__title">
        {t('search.city_search.popular_cities')}
      </div>
      <Loader loading={loading}>{citiesList}</Loader>
    </div>
  );
};

PopularCities.propTypes = {
  onChangeHandler: PropTypes.func,
  currentCity: PropTypes.string,
  setErrorUI: PropTypes.func
};

export default PopularCities;
