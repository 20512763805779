import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabPane = ({ children, name, additionalClasses }) => {
  const tabPaneClasses = classNames(
    'tabs__content_pane',
    `tabs__content_pane--${name}`,
    additionalClasses
  );
  return (
    <div className={tabPaneClasses} id={`tab-${name}`}>
      {children}
    </div>
  );
};

TabPane.propTypes = {
  currentTab: PropTypes.number,
  children: PropTypes.node,
  name: PropTypes.string,
  additionalClasses: PropTypes.string
};

export default TabPane;
