import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tab from './Tab';

import './styles.scss';

const Tabs = ({ children, switchTab, selected, scopeClass }) => {
  const renderTitles = (
    <ul className="tabs__list">
      {children.map((tab, i) => (
        <Tab
          key={i}
          name={tab.props.name}
          text={tab.props.label}
          currentTabId={selected}
          switchTab={switchTab}
          tabId={i}
          itemsCount={tab.props.itemsCount}
        />
      ))}
    </ul>
  );
  const selectedChild = React.Children.toArray(children)[selected];
  const renderContent = <div className="tabs__content">{selectedChild}</div>;

  return (
    <div className={classNames('tabs', `tabs--${scopeClass}`)}>
      {renderTitles}
      {renderContent}
    </div>
  );
};

Tabs.propTypes = {
  selected: PropTypes.number,
  switchTab: PropTypes.func,
  children: PropTypes.node,
  scopeClass: PropTypes.string
};

export default Tabs;
