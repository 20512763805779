import React from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAutoSuggestion from '../../../hooks/useAutoSuggestion';
import Loader from '../../Loader';
import RecentSearches from '../RecentSearches';
import SearchEmpty from '../SearchEmpty';
import SuggestionList from '../SearchSuggestionList';

const AutoSuggestion = ({
  searchString,
  cancelHandler,
  isFocused,
  client,
  isSubmittedForm
}) => {
  const { t } = useTranslation();
  const { data, loading, error } = useAutoSuggestion(client, searchString);
  const savedItems = JSON.parse(localStorage.getItem('last_ads')) || [];

  const lastSearchAdsList = !searchString &&
    !!savedItems.length &&
    !isSubmittedForm &&
    isFocused && <RecentSearches ads={savedItems} />;

  const emptySearch = !data.item &&
    (searchString.length > 7 || isSubmittedForm) && (
      <SearchEmpty
        onClickHandler={cancelHandler}
        linkText={t('search.ads_search.back')}
      />
    );
  const suggestion = searchString && data.item && (
    <Loader loading={loading}>
      <SuggestionList data={[data.item]} />
    </Loader>
  );

  if (error) {
    return null;
  }

  return (
    <>
      {emptySearch}
      {lastSearchAdsList}
      {suggestion}
    </>
  );
};

AutoSuggestion.propTypes = {
  closePopupHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  isFocused: PropTypes.bool,
  searchString: PropTypes.string,
  client: PropTypes.object,
  isSubmittedForm: PropTypes.bool
};

export default withApollo(AutoSuggestion);
